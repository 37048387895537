<template>
    <div>
        <div class="grid">
            <div v-for="(row, rowIndex) in grid" :key="rowIndex">
                <div v-for="(col, colIndex) in row" :key="colIndex"
                @mousedown.exact="startSelection(rowIndex, colIndex)" 
                @mousedown.ctrl.exact="startSelection(rowIndex, colIndex, true)" 
                @mousemove="handleDrag(rowIndex, colIndex)" 
                @mouseup.exact="endSelection(rowIndex, colIndex)" 
                @mouseup.ctrl.exact="endSelection(rowIndex, colIndex, true)"
                :class="{ 'grid-item': true, 'selected': isSelected(rowIndex, colIndex) || grid[rowIndex][colIndex] === true }"
                >
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        grid: Array.from({ length: 5 }, () => Array(5).fill(false)),
        selecting: false,
        startCell: { row: null, col: null },
        endCell: { row: null, col: null },
        isDeleting: false,
        layerMap: [],
      };
    },
    methods: {
      startSelection(rowIndex, colIndex, isDeleting = false) {
        this.startCell.row = rowIndex;
        this.startCell.col = colIndex;
        if (!isDeleting){
            this.selecting = true;
        } else {
            this.isDeleting = true
        }
      },
      handleDrag(rowIndex, colIndex) {
        if (this.selecting || this.isDeleting) {
            this.endCell.row = rowIndex;
            this.endCell.col = colIndex;
        }
      },
      endSelection(rowIndex, colIndex, isDeleting = false) {
        this.endCell.row = rowIndex;
        this.endCell.col = colIndex;
        this.selecting = false;
        this.isDeleting = false;
        this.updateGrid(isDeleting);
        this.startCell = { row: null, col: null };
        this.endCell = { row: null, col: null };

      },
      selectCell(row, col) {
        if (!this.selecting) {
          this.startCell = { row, col };
          this.endCell = { row, col };
        }
      },
      isSelected(row, col) {
        if ((this.selecting || this.isDeleting)
            && typeof this.startCell.row === 'number'
            && typeof this.startCell.col === 'number'
            && typeof this.endCell.row === 'number'
            && typeof this.endCell.col === 'number'){
            const minRow = Math.min(this.startCell.row, this.endCell.row);
            const maxRow = Math.max(this.startCell.row, this.endCell.row);
            const minCol = Math.min(this.startCell.col, this.endCell.col);
            const maxCol = Math.max(this.startCell.col, this.endCell.col);
            if (this.selecting){
                return row >= minRow && row <= maxRow && col >= minCol && col <= maxCol;
            }
            if (this.isDeleting){
                if (row >= minRow && row <= maxRow && col >= minCol && col <= maxCol){
                    if (this.grid[row][col]){
                        this.grid[row][col] = false
                        return false
                    }
                }
            }
            return false
        }
      },
      updateGrid(isDeleting = false){
        const minRow = Math.min(this.startCell.row, this.endCell.row);
        const maxRow = Math.max(this.startCell.row, this.endCell.row);
        const minCol = Math.min(this.startCell.col, this.endCell.col);
        const maxCol = Math.max(this.startCell.col, this.endCell.col);
        for (let row = minRow; row <= maxRow; row++){
            for (let col = minCol; col <= maxCol; col++){
                this.grid[row][col] = !isDeleting ? true : false;
                if(this.grid[row][col] && !this.layerMap.find(item => item.rowNum === row && item.colNum === col)){
                    this.layerMap.push({rowNum: row, colNum: col})
                }
                else if (!this.grid[row][col]) {
                    this.layerMap = this.layerMap.filter((item) => {return !(item.rowNum === row && item.colNum === col)})
                }
            }
        }
        this.$forceUpdate()
      }
    }
  };
  </script>
  
  <style scoped>
  .grid {
    display: grid;
    grid-template-columns: repeat(5, 30px);
    grid-template-rows: repeat(5, 30px);
    gap: 1px;
    border: 1px solid #ccc;
  }
  
  .grid-item {
    width: 30px;
    height: 30px;
    background-color: #eee;
    border: 1px solid #fff;
    user-select: none;
  }
  
  .selected {
    background-color: #a3d3f1;
  }
  </style>
  