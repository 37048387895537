<template>
  <div class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Information" class="mb-3" />
      </b-col>
      <b-col md="2 text-right">
        <b-button variant="primary" @click="openModal({})"> + Add </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        @click="openModal(info)"
        cols="6"
        v-for="(info, index) in list"
        :key="info._id || index"
      >
        <b-card>
          <template #header>
            <div
              class="
                d-flex
                justify-content-between
                align-items-center
                flex-grow-1
                w-100
              "
            >
              <span class="lead collapse-title">
                <h4>Language: {{ info.language }}</h4>
              </span>
              <div style="position: absolute; right: 5px; top: 12px">
                <b-button
                  variant="flat-danger"
                  @click.stop="confirmDelete(info._id)"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </div>
          </template>

          <h5>Trang web: {{ info.web }}</h5>
          <h5>Hòm thư hỗ trợ: {{ info.email }}</h5>
          <h5>Hotline: {{ info.hotline }}</h5>
          <h5>Community: {{ info.community }}</h5>
          <small class="d-block mt-2" style="color: #bbb">
            Last edited: {{ info.updatedAt | local_time_string }}
          </small>
        </b-card>
      </b-col>
    </b-row>
    <info-create-form ref="modalForm" @create="create" @update="update" />
    <test></test>
  </div>
</template>

<script>
import service from "../service";
import InfoCreateForm from "./_components/InfoCreateForm.vue";
import Test from './Test.vue'
export default {
  components: {
    InfoCreateForm,
    Test
  },
  data() {
    return {
      infoCompany: {},
      list: [],
    };
  },
  created() {
    this.getAll();
  },
  methods: {
    openModal(info) {
      this.$refs.modalForm.openModal(info);
    },
    async create(info) {
      let response = await service.create(info);
      if (response.data.type == "DATA") {
        this.$store.dispatch("pushSuccessNotify", { text: "Successfully!" });
        this.getAll();
      }
    },
    async getAll() {
      let response = await service.getAll();
      this.list = response.data.data.list;
    },
    async update(info) {
      await service.update({ data: JSON.stringify(info) });
      this.getAll();
    },
    async remove(id) {
      await service.delete({ id: id });
      this.getAll();
    },
    confirmDelete(id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.remove(id);
          }
        });
    },
  },
};
</script>
